import "./App.css";
import BannerBtn from "./Components/BannerBtn";
import Header from "./Components/Header";

function App() {
  return (
    <div className="App">
      <Header />
      <BannerBtn />
    </div>
  );
}

export default App;
