
import React, { useState, useRef, useEffect } from "react";

const ModuleBtn2 = ({ imageSrc, audioSrc, altText }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(audioSrc));

  useEffect(() => {
    const audio = audioRef.current;
    const handleEnded = () => setIsPlaying(false);

    audio.addEventListener("ended", handleEnded);
    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const toggleAudio = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <div className="card">
        <img src={imageSrc} alt={altText} />
        <button className="btn-audio" onClick={toggleAudio}>
          {isPlaying ? "Stop Audio" : "Play Audio"}
        </button>
      </div>
    </div>
  );
};

export default ModuleBtn2;
