import React from "react";
import "./Style.css";
import BannerImg from "./banner-image/banner.png";
import { GoogleOAuthProvider ,GoogleLogin } from '@react-oauth/google';
// import {  } from '@react-oauth/google';




export default function Header() {
  return (
    <>
      <header className="header">
        <div className="nav-bar">
          <div className="nav-logo">
            <p>Alphabet-Insight</p>
          </div>
          <div className="nav-btn">
            <GoogleOAuthProvider clientId="377065575453-2gs2mufe3paanabi21p5ebg17ds4gtst.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div>
        </div>
      </header>
      <div className="conatiner">
        <div className="banner">
          <img src={BannerImg} alt="banner-image" />
          <div className="banner-text">
            <h2>Learn Marathi Alphabets</h2>
            <p>
              Learning the Marathi alphabet is now easy and fun! On our website,
              you can learn the Marathi letters. Enhance your Marathi language
              skills through educational games, videos, and charts. Suitable for
              all ages, our website offers simple and effective ways to master
              the Marathi alphabet.
            </p>
            <p>Start today and explore the wonderful world of Marathi!</p>

            <p>Click on Below Buttons</p>
          </div>
        </div>
      </div>
    </>
  );
}
