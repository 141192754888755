import React, { useState, useEffect } from "react";
import Configration1Data from "./Configration1"
import "./Style.css"


const AudioLooper = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLooping, setIsLooping] = useState(false);

  useEffect(() => {
    const audio = new Audio(Configration1Data[currentIndex].audioSrc);

    const handleEnded = () => {
      if (isLooping) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % Configration1Data.length);
      }
    };

    if (isLooping) {
      audio.play();
      audio.addEventListener("ended", handleEnded);
    }

    return () => {
      audio.pause();
      audio.removeEventListener("ended", handleEnded);
    };
  }, [currentIndex, isLooping]);

  const handleStartLoop = () => {
    setIsLooping(true);
  };

  const handleStopLoop = () => {
    setIsLooping(false);
  };

  return (
    <div className="loopAudioBtn">
      <button onClick={handleStartLoop} disabled={isLooping}>Start Audio</button>
      <button onClick={handleStopLoop} disabled={!isLooping}>Stop Audio</button>
    </div>
  );
};

export default AudioLooper;
