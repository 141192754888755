import React, { useState } from "react";
import './Style.css'
import SoundComponent1 from "./Configration1";
import ModuleComponent1 from "./ModuleBtn1";
import SoundComponent2 from "./Configration2";
import ModuleComponent2 from "./ModuleBtn2";
import AudioLooper1 from "./AudioLooper1";
import AudioLooper2 from "./AudioLooper2"
function BannerBtn() {
    const [showComponent, setShowComponent] = useState(null);
  return (
    <div>
        <div className="app-container button-container">
        <div className="btn-content">
          <button onClick={() => setShowComponent("model1")}>view स्वर</button>
        </div>
        <div className="btn-content">
          <button onClick={() => setShowComponent("model2")}>view व्यंजन</button>
        </div>
      </div>

      {showComponent === "model1" && (
        <div className="app-container">
          <h1 className="heading">स्वर</h1>
          <AudioLooper2/>
          <div className="container">
            {SoundComponent2.map((soundData, index) => (
              <ModuleComponent2
                key={index}
                imageSrc={soundData.imageSrc}
                audioSrc={soundData.audioSrc}
                altText={soundData.altText}
              />
            ))}
          </div>
        </div>
      )}

      {showComponent === "model2" && (
        <div className="app-container">
          <h1 className="heading">व्यंजन</h1>
          <AudioLooper1/>
          <div className="container">
            {SoundComponent1.map((soundData, index) => (
              <ModuleComponent1
                key={index}
                imageSrc={soundData.imageSrc}
                audioSrc={soundData.audioSrc}
                altText={soundData.altText}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default BannerBtn
