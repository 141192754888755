import vowelsImg1 from "./images/Vowels-img1.png";
import audio1 from "./sound/k.mp3";
import vowelsImg2 from "./images/Vowels-img2.png";
import audio2 from "./sound/k.mp3";
import vowelsImg3 from "./images/Vowels-img3.png";
import audio3 from "./sound/k.mp3";
import vowelsImg4 from "./images/Vowels-img4.png";
import audio4 from "./sound/k.mp3";
import vowelsImg5 from "./images/Vowels-img5.png";
import audio5 from "./sound/k.mp3";

import vowelsImg6 from "./images/Vowels-img6.png";
import audio6 from "./sound/k.mp3";
import vowelsImg7 from "./images/Vowels-img7.png";
import audio7 from "./sound/k.mp3";
import vowelsImg8 from "./images/Vowels-img8.png";
import audio8 from "./sound/k.mp3";
import vowelsImg9 from "./images/Vowels-img9.png";
import audio9 from "./sound/k.mp3";
import vowelsImg10 from "./images/Vowels-img10.png";
import audio10 from "./sound/k.mp3";

import vowelsImg11 from "./images/Vowels-img11.png";
import audio11 from "./sound/k.mp3";
import vowelsImg12 from "./images/Vowels-img12.png";
import audio12 from "./sound/k.mp3";
import vowelsImg13 from "./images/Vowels-img13.png";
import audio13 from "./sound/k.mp3";

const SoundComponent2 = [
  {
    imageSrc: vowelsImg1,
    audioSrc: audio1,
  },
  {
    imageSrc: vowelsImg2,
    audioSrc: audio2,
  },
  {
    imageSrc: vowelsImg3,
    audioSrc: audio3,
  },
  {
    imageSrc: vowelsImg4,
    audioSrc: audio4,
  },
  {
    imageSrc: vowelsImg5,
    audioSrc: audio5,
  },

  {
    imageSrc: vowelsImg6,
    audioSrc: audio6,
  },
  {
    imageSrc: vowelsImg7,
    audioSrc: audio7,
  },
  {
    imageSrc: vowelsImg8,
    audioSrc: audio8,
  },
  {
    imageSrc: vowelsImg9,
    audioSrc: audio9,
  },
  {
    imageSrc: vowelsImg10,
    audioSrc: audio10,
  },

  {
    imageSrc: vowelsImg11,
    audioSrc: audio11,
  },
  {
    imageSrc: vowelsImg12,
    audioSrc: audio12,
  },
  {
    imageSrc: vowelsImg13,
    audioSrc: audio13,
  },
];

export default SoundComponent2;
