import img1 from "./images/img-1.png";
import audio1 from "./sound/k.mp3";
import img2 from "./images/img-2.png";
import audio2 from "./sound/sound-k.mp3";
import img3 from "./images/img-3.png";
import audio3 from "./sound/sound-k.mp3";
import img4 from "./images/img-4.png";
import audio4 from "./sound/k.mp3";
import img5 from "./images/img-5.png";
import audio5 from "./sound/sound-k.mp3";

import img6 from "./images/img-6.png";
import audio6 from "./sound/sound-k.mp3";
import img7 from "./images/img-7.png";
import audio7 from "./sound/sound-k.mp3";
import img8 from "./images/img-8.png";
import audio8 from "./sound/sound-k.mp3";
import img9 from "./images/img-9.png";
import audio9 from "./sound/sound-k.mp3";
import img10 from "./images/img-10.png";
import audio10 from "./sound/sound-k.mp3";

import img11 from "./images/img-11.png";
import audio11 from "./sound/sound-k.mp3";
import img12 from "./images/img-12.png";
import audio12 from "./sound/sound-k.mp3";
import img13 from "./images/img-13.png";
import audio13 from "./sound/sound-k.mp3";
import img14 from "./images/img-14.png";
import audio14 from "./sound/sound-k.mp3";
import img15 from "./images/img-15.png";
import audio15 from "./sound/sound-k.mp3";

import img16 from "./images/img-16.png";
import audio16 from "./sound/sound-k.mp3";
import img17 from "./images/img-17.png";
import audio17 from "./sound/sound-k.mp3";
import img18 from "./images/img-18.png";
import audio18 from "./sound/sound-k.mp3";
import img19 from "./images/img-19.png";
import audio19 from "./sound/sound-k.mp3";
import img20 from "./images/img-20.png";
import audio20 from "./sound/sound-k.mp3";

import img21 from "./images/img-21.png";
import audio21 from "./sound/sound-k.mp3";
import img22 from "./images/img-22.png";
import audio22 from "./sound/sound-k.mp3";
import img23 from "./images/img-23.png";
import audio23 from "./sound/sound-k.mp3";
import img24 from "./images/img-24.png";
import audio24 from "./sound/sound-k.mp3";
import img25 from "./images/img-25.png";
import audio25 from "./sound/sound-k.mp3";

import img26 from "./images/img-26.png";
import audio26 from "./sound/sound-k.mp3";
import img27 from "./images/img-27.png";
import audio27 from "./sound/sound-k.mp3";
import img28 from "./images/img-28.png";
import audio28 from "./sound/sound-k.mp3";

import img29 from "./images/img-29.png";
import audio29 from "./sound/sound-k.mp3";

import img30 from "./images/img-30.png";
import audio30 from "./sound/sound-k.mp3";
import img31 from "./images/img-31.png";
import audio31 from "./sound/sound-k.mp3";
import img32 from "./images/img-32.png";
import audio32 from "./sound/sound-k.mp3";
import img33 from "./images/img-33.png";
import audio33 from "./sound/sound-k.mp3";
import img34 from "./images/img-34.png";
import audio34 from "./sound/sound-k.mp3";

import img35 from "./images/img-35.png";
import audio35 from "./sound/sound-k.mp3";

const SoundComponent1 = [
  {
    imageSrc: img1,
    audioSrc: audio1,
  },
  {
    imageSrc: img2,
    audioSrc: audio2,
  },
  {
    imageSrc: img3,
    audioSrc: audio3,
  },
  {
    imageSrc: img4,
    audioSrc: audio4,
  },
  {
    imageSrc: img5,
    audioSrc: audio5,
  },

  {
    imageSrc: img6,
    audioSrc: audio6,
  },
  {
    imageSrc: img7,
    audioSrc: audio7,
  },
  {
    imageSrc: img8,
    audioSrc: audio8,
  },
  {
    imageSrc: img9,
    audioSrc: audio9,
  },
  {
    imageSrc: img10,
    audioSrc: audio10,
  },

  {
    imageSrc: img11,
    audioSrc: audio11,
  },
  {
    imageSrc: img12,
    audioSrc: audio12,
  },
  {
    imageSrc: img13,
    audioSrc: audio13,
  },
  {
    imageSrc: img14,
    audioSrc: audio14,
  },
  {
    imageSrc: img15,
    audioSrc: audio15,
  },

  {
    imageSrc: img16,
    audioSrc: audio16,
  },
  {
    imageSrc: img17,
    audioSrc: audio17,
  },
  {
    imageSrc: img18,
    audioSrc: audio18,
  },
  {
    imageSrc: img19,
    audioSrc: audio19,
  },
  {
    imageSrc: img20,
    audioSrc: audio20,
  },

  {
    imageSrc: img21,
    audioSrc: audio21,
  },
  {
    imageSrc: img22,
    audioSrc: audio22,
  },

  {
    imageSrc: img23,
    audioSrc: audio23,
  },
  {
    imageSrc: img24,
    audioSrc: audio24,
  },
  {
    imageSrc: img25,
    audioSrc: audio25,
  },
  {
    imageSrc: img26,
    audioSrc: audio26,
  },
  {
    imageSrc: img27,
    audioSrc: audio27,
  },

  {
    imageSrc: img28,
    audioSrc: audio28,
  },
  {
    imageSrc: img29,
    audioSrc: audio29,
  },
  {
    imageSrc: img30,
    audioSrc: audio30,
  },
  {
    imageSrc: img31,
    audioSrc: audio31,
  },
  {
    imageSrc: img32,
    audioSrc: audio32,
  },

  {
    imageSrc: img33,
    audioSrc: audio33,
  },
  {
    imageSrc: img34,
    audioSrc: audio34,
  },
  {
    imageSrc: img35,
    audioSrc: audio35,
  },
];

export default SoundComponent1;
